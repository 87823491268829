<template lang="">
    <header class="transparent scroll-light">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between">
                            <div class="align-self-center header-col-left">
                                <!-- logo begin -->
                                <!-- logo begin -->
                                <div id="logo">
                                    <a href="#">
                                        <img alt="" class="logo" src="assets/images/icon-simas1.png" />
                                        <img alt="" class="logo-2" src="assets/images/icon-simas2.png" />
                                    </a>
                                </div>
                                <!-- logo close -->
                                <!-- logo close -->
                            </div>
                            <div class="align-self-center ml-auto header-col-mid">
                                <!-- mainmenu begin -->
                                <ul id="mainmenu">
                                    <li>
                                        <a href="#" style="color: white;">Beranda</a>
                                    </li>
                                    <li>
                                        <a href="#" style="color: white;">Fitur</a>
                                        <ul>
                                            <li><a href="#">Tagihan Iuran</a></li>
                                            <li><a href="#">Pengajuan Surat</a></li>
                                            <li><a href="#">Laporan Keuangan</a></li>
                                            <li><a href="#">Kas RT</a></li>
                                            <li><a href="#">E-Warung</a></li>
                                            <li><a href="#">Kontak Darurat</a></li>
                                            <li><a href="#">Tetangga</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#" style="color: white;">Biaya</a>
                                    </li>
                                    <li>
                                        <a href="#" style="color: white;">Tentang Kami</a>
                                    </li>
                                    <li>
                                        <a href="#" style="color: white;">Daftar</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="align-self-center ml-auto header-col-right">
                                <a class="btn-custom" href="https://adena.simpelgo.com/">Login <i class="fa fa-arrow-right"></i> </a> 
                                <span id="menu-btn"></span>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
</template>
<script>
export default {
    name: 'HeaderHead'
}
</script>
<style scoped>
    .logo {
        width: 120px;
    }
    .logo-2 {
        width: 85px;
    }
</style>