<template lang="">
    <div class="no-bottom no-top" id="content">
            <div id="top"></div>

            <section data-bgimage="url(../assets/images/background/9.png) bottom"
                class="full-height no-top no-bottom vertical-center text-light">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5 wow fadeInRight" data-wow-delay=".5s">
                            <h1>SIMPELGO</h1>
                            <p class="lead">(Sistem Informasi Pengelolaan Lingkungan) atau SIMPEL adalah Platform Digital Berbasis Web untuk Pengurus RT dan Warga</p>
                            <div class="spacer-20"></div>
                            <button class="btn-custom">Lihat Fitur</button>
                            <!-- <a href="#"><img src="assets/images/misc/download-appstore.png"
                                    class="img-fluid"></a>&nbsp;
                            <a href="#"><img src="assets/images/misc/download-playstore.png"
                                    class="img-fluid"></a> -->
                            <div class="mb-sm-30"></div>
                        </div>

                        <div class="col-lg-6 offset-lg-1 wow fadeInLeft" data-wow-delay=".5s">
                            <img src="assets/images/mockup.png" class="img-fluid">
                        </div>

                        <div class="spacer-single"></div>
                    </div>
                </div>
            </section>

            <section id="section-highlight" data-bgimage="url(../assets/images/background/10.png) top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 mb30 wow fadeInRight" data-wow-delay="0s">
                            <div class="f-box f-icon-left f-icon-circle">
                                <i class="fa fa-file-text bg-color text-light"></i>
                                <div class="fb-text">
                                    <h4>Tagihan Iuran</h4>
                                    <p>pembayaran iuran warga menjadi lebih mudah. Cukup dengan beberapa sentuhan jari, iuran dapat dibayarkan melalui transfer bank.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mb30 wow fadeInRight" data-wow-delay=".25s">
                            <div class="f-box f-icon-left f-icon-circle">
                                <i class="fa fa-envelope bg-color text-light"></i>
                                <div class="fb-text">
                                    <h4>Pengajuan Surat</h4>
                                    <p>pengurusan administrasi publik di kelurahan. Fitur pengajuan surat pengantar memungkinkan Anda untuk meminta template atau surat dari RT dengan cepat dan mudah.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                            <div class="f-box f-icon-left f-icon-circle">
                                <i class="fa fa-line-chart bg-color text-light"></i>
                                <div class="fb-text">
                                    <h4>History</h4>
                                    <p>memantau tagihan bulanan dan tahunan. Anda bisa dengan mudah melihat tagihan yang sudah dibayarkan dan yang belum, semua dalam satu aplikasi.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay="0s">
                            <div class="f-box f-icon-left f-icon-circle">
                                <i class="fa fa-shopping-basket bg-color text-light"></i>
                                <div class="fb-text">
                                    <h4>E-Warung</h4>
                                    <p> jam buka, dan kontak warung dapat diakses dengan cepat, membantu Anda dalam mencari kebutuhan sehari-hari tanpa kesulitan.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".25s">
                            <div class="f-box f-icon-left f-icon-circle">
                                <i class="fa fa-phone bg-color text-light"></i>
                                <div class="fb-text">
                                    <h4>Kontak Darurat</h4>
                                    <p>Dapat digunakan untuk menghubungi kepolisian, Pemadam kebarakan, dan rumah sakit dalam situasi mendesak.</p>
                                </div>
                            </div>
                        </div>

                        <div class="spacer-double"></div>


                    </div>
                </div>


            </section>

            <section class="bg-color-3 text-light">
                <div class="text-center">
                    <h2>Screenshots</h2>
                    <div class="spacer-20"></div>
                </div>

                <div id="ss-carousel" class="owl-carousel owl-theme">

                    <div class="de-image-hover rounded">
                        <a href="assets/images/ss/1.jpg" class="image-popup">
                            <span class="dih-title-wrap">
                                <span class="dih-title">Screen Preview</span>
                            </span>
                            <span class="dih-overlay"></span>
                            <img src="assets/images/ss/1.png" class="img-fullwidth" alt="">
                        </a>
                    </div>

                    <div class="de-image-hover rounded">
                        <a href="assets/images/ss/2.png" class="image-popup">
                            <span class="dih-title-wrap">
                                <span class="dih-title">Screen Preview</span>
                            </span>
                            <span class="dih-overlay"></span>
                            <img src="assets/images/ss/2.png" class="img-fullwidth" alt="">
                        </a>
                    </div>

                    <div class="de-image-hover rounded">
                        <a href="assets/images/ss/3.png" class="image-popup">
                            <span class="dih-title-wrap">
                                <span class="dih-title">Screen Preview</span>
                            </span>
                            <span class="dih-overlay"></span>
                            <img src="assets/images/ss/3.png" class="img-fullwidth" alt="">
                        </a>
                    </div>

                    <div class="de-image-hover rounded">
                        <a href="assets/images/ss/4.png" class="image-popup">
                            <span class="dih-title-wrap">
                                <span class="dih-title">Screen Preview</span>
                            </span>
                            <span class="dih-overlay"></span>
                            <img src="assets/images/ss/4.png" class="img-fullwidth" alt="">
                        </a>
                    </div>

                    <div class="de-image-hover rounded">
                        <a href="assets/images/ss/5.png" class="image-popup">
                            <span class="dih-title-wrap">
                                <span class="dih-title">Screen Preview</span>
                            </span>
                            <span class="dih-overlay"></span>
                            <img src="assets/images/ss/5.png" class="img-fullwidth" alt="">
                        </a>
                    </div>

                    <div class="de-image-hover rounded">
                        <a href="assets/images/ss/6.png" class="image-popup">
                            <span class="dih-title-wrap">
                                <span class="dih-title">Screen Preview</span>
                            </span>
                            <span class="dih-overlay"></span>
                            <img src="assets/images/ss/6.png" class="img-fullwidth" alt="">
                        </a>
                    </div>

                </div>
            </section>
        </div>
</template>
<script>
export default {
    name: 'ContentSection'
}
</script>
<style lang="">
    
</style>