<template lang="">
    <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="widget">
                            <a href="#"><img alt="" class="logoFooter"
                                    src="assets/images/icon-simas1.png"></a>
                            <div class="spacer-20"></div>
                            <p>SIMPELGO (Sistem Informasi Pengelolaan Lingkungan) adalah sebuah platform digital yang di rancang untuk memfasilitasi interaksi dan kolaborasi antara pengurus dan warga</p>
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <div class="widget">
                            <h5>Pages</h5>
                            <ul>
                                <li><a href="">Beranda</a></li>
                                <li><a href="">Fitur</a></li>
                                <li><a href="">Biaya</a></li>
                                <li><a href="">Tentang kami</a></li>
                                <li><a href="">Daftar</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <div class="widget">
                            <h5>Pages</h5>
                            <ul>
                                <li><a href="">Beranda</a></li>
                                <li><a href="">Fitur</a></li>
                                <li><a href="">Biaya</a></li>
                                <li><a href="">Tentang kami</a></li>
                                <li><a href="">Daftar</a></li>
                            </ul>
                        </div>
                    </div>


                    <div class="col-lg-4">
                        <div class="widget">
                            <h5>Hubungi Kami</h5>

                            <p>Untuk berkolaborasi bersama kami.</p>
                           <p>PT. Berkah Jaya Nugraha <br> Graha Adena SR 1 / 33 Pondok Jagung Timur, Serpong Utara</p>
                           <a href="https://www.instagram.com/simpel.go/"><i class="fa fa-envelope fa-lg"></i> berkahjana@gmail.com</a> <br>
                           <a href="https://www.instagram.com/simpel.go/"><i class="fa fa-whatsapp fa-lg"></i> 6289106658917</a>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 sm-text-center mb-sm-30">
                        <div class="mt10">&copy; Copyright 2024 - Simpelgo by Berkah Jaya Nugraha</div>
                    </div>

                    <div class="col-md-6 text-md-right text-sm-left">
                        <div class="social-icons">
                            <a href="https://www.instagram.com/simpel.go/"><i class="fa fa-instagram fa-lg"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
</template>
<script>
export default {
    name: 'FooterSection'
}
</script>
<style scoped>
.logoFooter {
    width: 100px;
}
</style>